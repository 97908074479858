const PRODUCTION = 'production'

const getEnvironment = () => window._env_.reactAppEnvironment || PRODUCTION

const checkEnvironment = (match: string) => {
  const environment = getEnvironment()
  const isMatch = environment?.includes(match)

  return isMatch
}

export const checkIsProductionApp = () => checkEnvironment(PRODUCTION)

export default getEnvironment
