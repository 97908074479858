/* eslint-disable prefer-destructuring */
/// <reference types="@types/segment-analytics" />

/* eslint-disable class-methods-use-this, 
no-unused-expressions, 
@typescript-eslint/no-unused-expressions 
*/

import { Analytics, UserTraits, GroupTraits, Platform } from './analytics'

declare global {
  interface Window {
    logTracking: (val?: boolean) => void
  }
}

export class SegmentAnalytics implements Analytics {
  id: string

  logTracking: boolean

  constructor(id: string) {
    this.id = id
    this.logTracking =
      localStorage?.getItem?.('__logTracking') === 'true' || false
  }

  init(): void {
    const notProduction = ['staging', 'localhost'].some((host) =>
      window.location.host.startsWith(host)
    )

    if (notProduction) {
      window.logTracking = (val?: boolean) => {
        this.logTracking = val ?? !this.logTracking
        localStorage.setItem('__logTracking', String(this.logTracking))
      }
    }

    if (!this.id && notProduction) {
      // eslint-disable-next-line no-console
      console.error('Segment ID is not valid')
      return
    }

    const apiHost = window._env_.segmentApiUrl

    window.analytics?.load(this.id, {
      integrations: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Segment.io': {
          apiHost: `${apiHost}/v1`,
        },
      },
    })
  }

  trackRouteChange(referrer: string | null) {
    const options = referrer
      ? {
          referrer,
        }
      : {}
    const payloadWithPlatform = {
      ...options,
      platform: this.getUserInfo().platform,
    }

    window.analytics?.page(payloadWithPlatform)
  }

  identifyUser(
    userId: string | number,
    traits: UserTraits,
    callback?: () => void
  ) {
    window.analytics?.identify(`${userId}`, traits, {}, callback)
  }

  reset() {
    window.analytics?.reset()
  }

  getUserInfo = () => {
    /* The group may not be set if:
      - analytics.associateUser() has not been called yet (race condition)
      - User has blocked Segment tracking (eg Adblock) */
    const group = window.analytics?.group()
    const groupId = (group && group.id && group.id()) || null

    const platform: Platform | null =
      /* traits is typed as 'void' in the SDK types, but it's not void */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (group && group.traits && group.traits().platform) || null

    const nonprofitOrgId = groupId && platform === 'nonprofit' ? groupId : null
    const funderOrgId = groupId && platform === 'funder' ? groupId : null

    return { platform, nonprofitOrgId, funderOrgId }
  }

  calculateRandomColorForString(str: string): string {
    const seed = str
      .split('')
      .reduce<number>((sum, char) => sum + char.charCodeAt(0), str.length)

    // Seeded random generator from here: https://stackoverflow.com/a/19303725
    // Just wanted the shortest one
    const red = Math.floor(
      (Math.sin(seed + 1) * 10000 - Math.floor(Math.sin(seed + 1) * 10000)) *
        // Don’t let the color go above 127 out of 255 to ensure constrast on white
        127
    )
    const green = Math.floor(
      (Math.sin(seed + 2) * 10000 - Math.floor(Math.sin(seed + 2) * 10000)) *
        127
    )
    const blue = Math.floor(
      (Math.sin(seed + 3) * 10000 - Math.floor(Math.sin(seed + 3) * 10000)) *
        127
    )

    return `rgb(${red}, ${green}, ${blue})`
  }

  /**
   * @param action - One of the actions defined in trackActionInterface
   * @param context - Dictionary of extra information that provides useful context about a message, but is not directly related to the API call
   * @param callback - A function that runs after a timeout of 300 ms, giving the browser time to make outbound requests first.
   */
  trackActionCreator(
    action: any,
    // eslint-disable-next-line default-param-last
    context: Record<string, unknown> | null | undefined = null,
    callback?: () => void
  ) {
    let { domain, subdomain } = action.config
    const { nonprofitOrgId, funderOrgId, platform } = this.getUserInfo()

    return (payload: ReturnType<typeof action>) => {
      // SUPPORT EVENTS THAT CAN BE TRIGGERED FROM DIFFERENT (SUB)DOMAINS
      if (payload.useWindowLocation) {
        domain = window.location.pathname.split('/')[1]
        domain = domain.charAt(0).toUpperCase() + domain.slice(1)
        if (window.location.pathname.split('/')[2]) {
          subdomain = window.location.pathname.split('/')[2]
          subdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1)
        } else {
          subdomain = null
        }
        // eslint-disable-next-line no-param-reassign
        delete payload.useWindowLocation
      }
      const actionName = subdomain
        ? `${domain}_${subdomain}_${Object.keys(action)[0]}`
        : `${domain}_${Object.keys(action)[0]}`

      let email = null

      try {
        /* traits is typed as 'void' in the SDK types, but it's not void */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        email = window.analytics.user().traits().email
      } catch (error) {
        // Error accessing email from window.analytics
        // The email will be set to null
      }

      const defaultParams = {
        domain,
        subdomain,
        nonprofitOrgId,
        funderOrgId,
        platform,
        email,
        ...payload,
      }

      if (this.logTracking) {
        const domainColor =
          typeof domain === 'string'
            ? this.calculateRandomColorForString(domain)
            : 'black'
        // eslint-disable-next-line no-console
        console.log(
          `%cTracked event: %c✨${actionName}✨`,
          `color: #003F7E; font-family: 'Apple Chancery'; font-size: 16px`,
          `color: ${domainColor}; font-size: 12px`,
          defaultParams
        )
      }

      if (context === null) {
        window.analytics?.track(actionName, defaultParams, {
          context: { platform },
        })
      } else {
        window.analytics?.track(
          actionName,
          defaultParams,
          { context: { ...context, platform } },
          callback
        )
      }
    }
  }

  trackCreator<T>(config: {
    eventName: string
    domain: string
    subdomain?: string
  }) {
    let { domain, subdomain } = config
    const { nonprofitOrgId, funderOrgId, platform } = this.getUserInfo()

    return (
      payload: T,
      options?: {
        useWindowLocation?: boolean
        additionalContext?: Record<string, unknown>
      }
    ) => {
      // SUPPORT EVENTS THAT CAN BE TRIGGERED FROM DIFFERENT (SUB)DOMAINS
      if (options?.useWindowLocation) {
        domain = window.location.pathname.split('/')[1]
        domain = domain.charAt(0).toUpperCase() + domain.slice(1)
        if (window.location.pathname.split('/')[2]) {
          subdomain = window.location.pathname.split('/')[2]
          subdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1)
        } else {
          subdomain = ''
        }
      }

      const actionName = subdomain
        ? `${domain}_${subdomain}_${config.eventName}`
        : `${domain}_${config.eventName}`

      let email = null

      try {
        // @ts-expect-error: traits is typed as 'void' in the SDK types, but it's not void
        email = window.analytics.user().traits().email
      } catch (error) {
        // Error accessing email from window.analytics
        // The email will be set to null
      }

      const defaultParams = {
        domain,
        subdomain,
        nonprofitOrgId,
        funderOrgId,
        platform,
        email,
        ...payload,
      }

      if (this.logTracking) {
        const domainColor =
          typeof domain === 'string'
            ? this.calculateRandomColorForString(domain)
            : 'black'
        // eslint-disable-next-line no-console
        console.log(
          `%cTracked event: %c✨${actionName}✨`,
          `color: #003F7E; font-family: 'Apple Chancery'; font-size: 16px`,
          `color: ${domainColor}; font-size: 12px`,
          defaultParams
        )
      }

      window.analytics?.track(actionName, defaultParams, {
        context: { ...(options?.additionalContext ?? {}), platform },
      })
    }
  }

  associateUser(groupId: string, traits: GroupTraits) {
    window.analytics?.group(groupId, traits)
  }
}
