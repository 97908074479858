import getEnvironment, { checkIsProductionApp } from './handleEnvironment'

const setFavicon = (iconUri: string) => {
  let favicon = document.getElementById('favicon')

  favicon!.setAttribute('href', iconUri)
}

const setAppName = (environment: string) => {
  const appName = document.title
  const capitalized = environment.replace(/^./, environment[0].toUpperCase())
  const updatedName = `${appName} • ${capitalized} Development`

  document.title = updatedName
}

const handleEnvironmentBranding = () => {
  const environment = getEnvironment()
  const isProductionApp = checkIsProductionApp()

  if (isProductionApp) return

  const uri = `/brand-marks/pictorial-marks/ico/${environment}.ico`

  setFavicon(uri)
  setAppName(environment)
}

export default handleEnvironmentBranding
