/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { Auth0Provider, User } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider'

type Props = {
  children: React.ReactNode
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const history = useHistory()

  const onRedirectCallback = async (appState?: AppState, user?: User) => {
    if (user) {
      const url = `${window.location.protocol}//${window._env_.apiURI}/graphql`
      const anonymousId = analytics?.user?.()?.anonymousId?.()
      window.setLastLoggedIn = true

      const query = `mutation {
          recordLoginEvent(
            email: "${user.email}",
            anonymousId: "${anonymousId}"
          ) {
            lastLogin
          }
        }`

      const fetchOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      }

      // Using Fetch instead of Apollo Mutation, because Apollo requires 'AuthorizedApolloProvider' to run migrations which is nested after this component
      // Don't await, because the user should be able to start doing things before we've recorded the event
      fetch(url, fetchOptions)
        .then((response) => response.json())
        .then(({ data }) => {
          if (data.updateLastLoginHubspot === null) {
            console.error(
              'Error: Hubspot App User Last Login Date Field not updated.'
            )
          }
        })
        .catch((error) => {
          console.error(
            'Error: Hubspot App User Last Login Date Field not updated:'
          )
          console.error(error)
        })
    }

    history.push(appState?.returnTo || window.location.pathname)
  }

  const redirectUri = window.location.origin

  return (
    <Auth0Provider
      clientId={window._env_.auth0ClientId}
      domain={window._env_.auth0Domain}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: window._env_.auth0Audience,
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
